/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
  position: relative;
  height: 680px;
  width: 100%;
  overflow: hidden;  
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @include bp-md-down {
    height: auto;
    padding: 60px 0;
  }

  .container {
    height: 100%;
  }

  &__holder {
    width: 100%;
    height: 100%;
    display: table;
  }

  &__inner {
    display: table-cell;
    vertical-align: middle;
  }

  &__title {
    font-size: 70px;
    line-height: 1.1;
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;

    @include bp-md-down {
      font-size: 50px;
    }

    @include bp-xs-down {
      font-size: 32px;
    }
  }
}