/*-------------------------------------------------------*/
/* Lightboxes
/*-------------------------------------------------------*/
.mfp-iframe-holder .mfp-content {
  max-width: 1400px;
}

.modal-body,
.modal-footer {
  padding: 30px;
}

.modal-header {
  padding: 15px 30px;
}