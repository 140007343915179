/*-------------------------------------------------------*/
/* Alert Boxes
/*-------------------------------------------------------*/

.alert {
  padding: 9px 18px;
  margin-bottom: 20px;
  border: none;
  color: #fff;
  border-radius: 3px;
  
  strong {
    color: inherit;
  }
}

.alert-dismissible .close {
  right: 0;
  top: 0;
  color: inherit;
  position: relative;
}

.close {
  opacity: 1;
  text-shadow: none;
  font-weight: normal;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-header .close {
  font-size: 25px;
  margin-top: 2px !important;
}

.alert-success { background-color: #e5f9ee; color: #1abc9c; }
.alert-info { background-color: #e9f4f6; color: #23abc4; }
.alert-warning { background-color: #faf8f0; color: #b1804c; }
.alert-danger { background-color: #faefee; color: #e95e49; }
