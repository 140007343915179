/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/

ul, ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 10px;
  color: $heading-color;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.04em;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: $heading-color;
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@include bp-lg-down {
  .text-lg-center {
    text-align: center
  }
;
}

@include bp-md-down {
  .text-md-center {
    text-align: center
  }
;
}

@include bp-sm-down {
  .text-sm-center {
    text-align: center
  }
;
}

@include bp-xs-down {
  .text-xs-center {
    text-align: center
  }
;
}

.section-headings p {
  margin-bottom: 40px;
}

.heading {
  position: relative;
  margin-bottom: 0;

  &--lg {
    font-size: 42px;

    @include bp-md-down {
      font-size: 28px;
    }
  }
}

.heading-row {
  margin-bottom: 70px;

  @include bp-md-down {
    margin-bottom: 40px;
  }
}

.subheading {
  font-size: 18px;
  max-width: 770px;
  line-height: 30px;
  padding: 0 15px;
  margin: 25px auto 0;
  color: $subheading-color;

  &-left-align {
    margin-left: 0;
    padding-left: 0;
  }
}

a {
  text-decoration: none;
  color: $main-color;
  @include transition (color .1s ease-in-out);

  &:hover {
    text-decoration: none;
    color: $bg-dark;
  }

  &:focus {
    color: $main-color;
    text-decoration: none;
  }
}

p {
  font-size: 15px;
  color: $text-color;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 10px;
}

.lead {
  font-size: 19px;
  line-height: 30px;
}

address {
  font-style: normal;
}

/* List Items
-------------------------------------------------------*/
.list-dividers,
.list-no-dividers {
  list-style: none;
}

.list-dividers > li {
  position: relative;
  padding: 9px 0;
  border-bottom: 1px solid $border-color;
}

.list-no-dividers > li {
  position: relative;
  padding: 5px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.list-inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }
}

/* Bottom line
-------------------------------------------------------*/
.bottom-line:after {
  content: "";
  display: block;
  width: 43px;
  margin: 15px auto 0;
  z-index: 1;
  position: relative;
}

.bottom-line-left-align:after {
  margin: 15px auto 0 0;
}

.bottom-line-full:after {
  width: 100%;
  border-bottom: 2px solid $border-color !important;
}

/* Blockquote
-------------------------------------------------------*/

blockquote {
  padding: 40px 50px;
  background-color: $bg-light;
  margin: 30px 0;
  border: none;
  border-radius: 5px;
  text-align: center;

  & > p {
    font-size: 22px;
    line-height: 34px;
    font-style: italic;
    font-family: $heading-font;
    margin-bottom: 10px;
    letter-spacing: -0.04em;
    position: relative;
  }

  span {
    font-family: $heading-font;
    letter-spacing: -0.04em;
    color: $heading-color;
    font-size: 14px;
  }
}

/* Dropcap
-------------------------------------------------------*/

.dropcap {
  float: left;
  font-family: $heading-font;
  color: $main-color;
  font-size: 56px;
  line-height: 48px;
  padding-right: 10px;
}

.highlight {
  padding: 3px 5px;
  color: #fff;
  background-color: $main-color;
}

/* Bullets and lists
-------------------------------------------------------*/

.list-arrows,
.list-checks {
  list-style-position: initial;
  list-style-type: none;

  i {
    margin-right: 10px;
    display: inline-block;
  }
}

.section-columns p {
  margin-bottom: 40px;
}

