// Colors
$text-color: #929292;
$alt-text-color: #000;
$heading-color: #212121;
$nav-color: #292b32;
$subheading-color: #7d7d7d;
$bg-light: #fafafa;
$bg-dark: #212121;
$border-color: #e8e8e8;
$border-color-dark: #313131;
$main-color: #ee2722;
$alt-main-color: #000;
$date-color: #ababab;


$gradient-dark: #ee2722;

// Fonts
$body-font: 'Lato', sans-serif;
$heading-font: 'Karla', sans-serif;
$alt-font: 'Merriweather', serif;


// Navbar
$nav-full-height: 72px;
$logo-height: 52px;
$nav-short-height: 60px;

$screen-md-min: 990px;