.link {

  &--underline {
    color: $gradient-dark;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }
}

.hero {
  &__title {
    font-family: $heading-font;
    letter-spacing: -3px;
    color: #FFF;
  }
}

.brand-bg {
  background: $main-color;
  &-alt {
    background: $alt-main-color;
  }
}

.brand-color {
  color: $main-color;
  &-alt {
    color: $alt-text-color
  }
}

.square {

  &__container {
    position: relative;
    &:hover {

      span {
        color: $text-color;
        background: none;

      }
    }
  }

  &--half {
    color: #FFF;
    position: absolute;
    width: 70px;
    background: #ee2722;
    bottom: -2px;
    padding: 10px 0 0 5px;
    line-height: 40px;
    margin-left: 5px;

  }

  &--first {
    padding-right: 1px;
  }

}

.hero {
  &--intro {
    background: #000;
    background-image: url('../img/hero/our_office_hero_image.png');
  }
}

.section {

  &--contact {
    background: $main-color;
  }

}

.footer {

  &__widgets {
    position: relative;
    a {
      color: $main-color;
    }
  }

  &__logo {
    position: absolute;
    bottom: 20px;
    right: 0;

    img {
      max-height: 200px;
    }
  }

  &__get-in-touch {

    &--wrapper {
      padding: 0 0 10px;

    }

    &__heading {
      margin: 0;
    }

  }

}

.contact-item {

  &__link {

    color: #FFF;
    border-bottom: 1px solid $alt-main-color;
    &:active, &:focus, &:visited {
      color: #000;
    }

    &--light {

      color: #FFF;

      &:active, &:focus, &:visited {
        color: $main-color;
      }
    }

  }

  &--form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
    input[type="submit"] {
      display: block;
      width: 100%;
      background: #fff;
      border: 0;
      color: black !important;
      &:hover {
        background: $main-color;
        color: #FFF;
      }
    }
    label {
      color: #FFF;
    }
  }
}

.page-title {
  height: 400px;
  &--sm {

  }
}

.partners-icon {

  &--small {
    max-height: 82px;
    padding: 20px;
  }
}

.testimonial {
  &__body {
    padding-left: 100px;
  }
  &__img {
    margin-top: 18px;
  }
}

.promo-section {
  &____description {
    padding-left: 10px;
  }
}

.nav__header {
  .logo {
    top: 0;
  }
}

.nav__holder {

  &.sticky.scrolling {
    background: rgba(255, 255, 255, 0.4);
  }
}

.heading {
  &--light {
    color: #FFF;
  }
}

.link {
  &-more {
    padding-bottom: 15px;
    padding-top: 15px;
    display: block;
  }
}

.text {
  letter-spacing: 1px;

  &--big {
    font-size: 20px;
  }

  &--big + &--big {
    margin-top: 15px;
  }

}