/*-------------------------------------------------------*/
/* Accordions and Toggles
/*-------------------------------------------------------*/

.accordion {

  &-panel {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  } 

  &-panel__heading {
    position: relative;
    background-color: $bg-light;
    padding: 0;
    @include transition ($all);


    &:hover {
      background-color: darken( $bg-light, 3% );
    }

    & > a.minus {
      color: #fff;
      background-color: $main-color;
      border-color: transparent;
    }

    & > a > span {
      display: block;
      width: 12px;
      height: 2px;
      background-color: #fff;
      right: 18px;
      top: 22px;
      position: absolute;
    }

    & > a.plus > span {
      background-color: $text-color;
    }

    & > a.plus > span:after {
      content: "";
      display: block;
      width: 12px;
      height: 2px;
      position: absolute;
      top: 0;
      background-color: $text-color;
      @include transform (rotate(-90deg));
    }
  }   
}


.accordion-panel__body,
.toggle-panel__body {
  border-top-color: transparent;
  padding: 24px 30px 20px;
  line-height: 26px;
}

.accordion-panel__heading > a,
.toggle-panel > a {
  display: block;
  position: relative;
  padding: 11px 20px;
  font-family: $heading-font;
  color: $heading-color;
  font-size: 15px;
  letter-spacing: -0.04em;
  border-radius: 5px;
  @include transition ($all);
}

.accordion-panel__heading > a {
  border: 1px solid $border-color;
}

.toggle-panel > a {
  border-bottom: 1px solid $border-color;
  padding: 14px 0;

  &:after {
    font-family: "ui-icons";
    position: absolute;
    content: "\e802";
    right: 0;
    top: 12px;
    color: $text-color;
    font-size: 18px;
  }

  &.active:after {
    content: "\e809";
    color: $heading-color;
  }
}

.toggle-panel__body {
  padding: 20px 0 10px;
  border: none;
}