/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.no-margin {	margin: 0!important; }
.no-padding { padding: 0!important; }
.mt-0 { margin-top: 0!important; }
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-80 { margin-top: 80px; }
.mt-90 { margin-top: 90px; }
.mt-100 {	margin-top: 100px; }
.mt-110 {	margin-top: 110px; }
.mt-120 {	margin-top: 120px; }
.mt-130 {	margin-top: 130px; }
.mt-140 {	margin-top: 140px; }
.mb-0 {	margin-bottom: 0!important; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-80 { margin-bottom: 80px; }
.mb-90 { margin-bottom: 90px; }
.mb-100 {	margin-bottom: 100px; }
.mb-110 {	margin-bottom: 110px; }
.mb-120 {	margin-bottom: 120px; }
.mb-130 {	margin-bottom: 130px; }
.mb-140 {	margin-bottom: 140px; }
.pt-0 {	padding-top: 0; }
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 {	padding-top: 100px; }
.pt-110 {	padding-top: 110px; }
.pt-120 {	padding-top: 120px; }
.pt-130 {	padding-top: 130px; }
.pt-140 {	padding-top: 140px; }
.pt-150 {	padding-top: 150px; }
.pt-160 {	padding-top: 160px; }
.pt-170 {	padding-top: 170px; }
.pt-180 {	padding-top: 180px; }
.pt-190 {	padding-top: 190px; }
.pt-200 {	padding-top: 200px; }
.pb-0 {	padding-bottom: 0; }
.pb-10 { padding-bottom: 10px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 {	padding-bottom: 100px; }
.pb-110 {	padding-bottom: 110px; }
.pb-120 {	padding-bottom: 120px; }
.pb-130 {	padding-bottom: 130px; }
.pb-140 {	padding-bottom: 140px; }
.pb-150 {	padding-bottom: 150px; }
.pb-160 {	padding-bottom: 160px; }
.pb-170 {	padding-bottom: 170px; }
.pb-180 {	padding-bottom: 180px; }
.pb-190 {	padding-bottom: 190px; }
.pb-200 {	padding-bottom: 200px; }

@media only screen and (max-width:1199px) {
.mt-lg-0 {	margin-top: 0!important; }
.mt-lg-10 { margin-top: 10px; }
.mt-lg-20 { margin-top: 20px; }
.mt-lg-30 { margin-top: 30px; }
.mt-lg-40 { margin-top: 40px; }
.mt-lg-50 { margin-top: 50px; }
.mt-lg-60 { margin-top: 60px; }
.mt-lg-70 { margin-top: 70px; }
.mt-lg-80 { margin-top: 80px; }
.mt-lg-90 { margin-top: 90px; }
.mt-lg-100 {	margin-top: 100px; }
.mt-lg-110 {	margin-top: 110px; }
.mt-lg-120 {	margin-top: 120px; }
.mt-lg-130 {	margin-top: 130px; }
.mt-lg-140 {	margin-top: 140px; }
.mb-lg-0 {	margin-bottom: 0!important; }
.mb-lg-10 { margin-bottom: 10px; }
.mb-lg-20 { margin-bottom: 20px; }
.mb-lg-30 { margin-bottom: 30px; }
.mb-lg-40 { margin-bottom: 40px; }
.mb-lg-50 { margin-bottom: 50px; }
.mb-lg-60 { margin-bottom: 60px; }
.mb-lg-70 { margin-bottom: 70px; }
.mb-lg-80 { margin-bottom: 80px; }
.mb-lg-90 { margin-bottom: 90px; }
.mb-lg-100 {	margin-bottom: 100px; }
.mb-lg-110 {	margin-bottom: 110px; }
.mb-lg-120 {	margin-bottom: 120px; }
.mb-lg-130 {	margin-bottom: 130px; }
.mb-lg-140 {	margin-bottom: 140px; }
.pt-lg-0 {	padding-top: 0; }
.pt-lg-10 { padding-top: 10px; }
.pt-lg-20 { padding-top: 20px; }
.pt-lg-30 { padding-top: 30px; }
.pt-lg-40 { padding-top: 40px; }
.pt-lg-50 { padding-top: 50px; }
.pt-lg-60 { padding-top: 60px; }
.pt-lg-70 { padding-top: 70px; }
.pt-lg-80 { padding-top: 80px; }
.pt-lg-90 { padding-top: 90px; }
.pt-lg-100 {	padding-top: 100px; }
.pt-lg-110 {	padding-top: 110px; }
.pt-lg-120 {	padding-top: 120px; }
.pt-lg-130 {	padding-top: 130px; }
.pt-lg-140 {	padding-top: 140px; }
.pb-lg-0 {	padding-bottom: 0; }
.pb-lg-10 { padding-bottom: 10px; }
.pb-lg-20 { padding-bottom: 20px; }
.pb-lg-30 { padding-bottom: 30px; }
.pb-lg-40 { padding-bottom: 40px; }
.pb-lg-50 { padding-bottom: 50px; }
.pb-lg-60 { padding-bottom: 60px; }
.pb-lg-70 { padding-bottom: 70px; }
.pb-lg-80 { padding-bottom: 80px; }
.pb-lg-90 { padding-bottom: 90px; }
.pb-lg-100 {	padding-bottom: 100px; }
.pb-lg-110 {	padding-bottom: 110px; }
.pb-lg-120 {	padding-bottom: 120px; }
.pb-lg-130 {	padding-bottom: 130px; }
.pb-lg-140 {	padding-bottom: 140px; }
}

@media only screen and (max-width:991px) {
.mt-md-0 { margin-top: 0!important; }
.mt-md-10 { margin-top: 10px; }
.mt-md-20 { margin-top: 20px; }
.mt-md-30 { margin-top: 30px; }
.mt-md-40 { margin-top: 40px; }
.mt-md-50 { margin-top: 50px; }
.mt-md-60 { margin-top: 60px; }
.mt-md-70 { margin-top: 70px; }
.mt-md-80 { margin-top: 80px; }
.mt-md-90 { margin-top: 90px; }
.mt-md-100 { margin-top: 100px; }
.mt-md-110 { margin-top: 110px; }
.mt-md-120 { margin-top: 120px; }
.mt-md-130 { margin-top: 130px; }
.mt-md-140 { margin-top: 140px; }
.mb-md-0 { margin-bottom: 0!important; }
.mb-md-10 { margin-bottom: 10px; }
.mb-md-20 { margin-bottom: 20px; }
.mb-md-30 { margin-bottom: 30px; }
.mb-md-40 { margin-bottom: 40px; }
.mb-md-50 { margin-bottom: 50px; }
.mb-md-60 { margin-bottom: 60px; }
.mb-md-70 { margin-bottom: 70px; }
.mb-md-80 { margin-bottom: 80px; }
.mb-md-90 { margin-bottom: 90px; }
.mb-md-100 { margin-bottom: 100px; }
.mb-md-110 { margin-bottom: 110px; }
.mb-md-120 { margin-bottom: 120px; }
.mb-md-130 { margin-bottom: 130px; }
.mb-md-140 { margin-bottom: 140px; }
.pt-md-0 { padding-top: 0; }
.pt-md-10 { padding-top: 10px; }
.pt-md-20 { padding-top: 20px; }
.pt-md-30 { padding-top: 30px; }
.pt-md-40 { padding-top: 40px; }
.pt-md-50 { padding-top: 50px; }
.pt-md-60 { padding-top: 60px; }
.pt-md-70 { padding-top: 70px; }
.pt-md-80 { padding-top: 80px; }
.pt-md-90 { padding-top: 90px; }
.pt-md-100 { padding-top: 100px; }
.pt-md-110 { padding-top: 110px; }
.pt-md-120 { padding-top: 120px; }
.pt-md-130 { padding-top: 130px; }
.pt-md-140 { padding-top: 140px; }
.pt-md-150 { padding-top: 150px; }
.pb-md-0 { padding-bottom: 0; }
.pb-md-10 { padding-bottom: 10px; }
.pb-md-20 { padding-bottom: 20px; }
.pb-md-30 { padding-bottom: 30px; }
.pb-md-40 { padding-bottom: 40px; }
.pb-md-50 { padding-bottom: 50px; }
.pb-md-60 { padding-bottom: 60px; }
.pb-md-70 { padding-bottom: 70px; }
.pb-md-80 { padding-bottom: 80px; }
.pb-md-90 { padding-bottom: 90px; }
.pb-md-100 { padding-bottom: 100px; }
.pb-md-110 { padding-bottom: 110px; }
.pb-md-120 { padding-bottom: 120px; }
.pb-md-130 { padding-bottom: 130px; }
.pb-md-140 { padding-bottom: 140px; }
.pb-md-150 { padding-bottom: 150px; }
}

@media only screen and (max-width:767px) {
.mt-sm-0 { margin-top: 0!important; }
.mt-sm-10 { margin-top: 10px; }
.mt-sm-20 { margin-top: 20px; }
.mt-sm-30 { margin-top: 30px; }
.mt-sm-40 { margin-top: 40px; }
.mt-sm-50 { margin-top: 50px; }
.mt-sm-60 { margin-top: 60px; }
.mt-sm-70 { margin-top: 70px; }
.mt-sm-80 { margin-top: 80px; }
.mt-sm-90 { margin-top: 90px; }
.mt-sm-100 { margin-top: 100px; }
.mt-sm-110 { margin-top: 110px; }
.mt-sm-120 { margin-top: 120px; }
.mt-sm-130 { margin-top: 130px; }
.mt-sm-140 { margin-top: 140px; }
.mb-sm-0 { margin-bottom: 0!important; }
.mb-sm-10 { margin-bottom: 10px; }
.mb-sm-20 { margin-bottom: 20px; }
.mb-sm-30 { margin-bottom: 30px; }
.mb-sm-40 { margin-bottom: 40px; }
.mb-sm-50 { margin-bottom: 50px; }
.mb-sm-60 { margin-bottom: 60px; }
.mb-sm-70 { margin-bottom: 70px; }
.mb-sm-80 { margin-bottom: 80px; }
.mb-sm-90 { margin-bottom: 90px; }
.mb-sm-100 { margin-bottom: 100px; }
.mb-sm-110 { margin-bottom: 110px; }
.mb-sm-120 { margin-bottom: 120px; }
.mb-sm-130 { margin-bottom: 130px; }
.mb-sm-140 { margin-bottom: 140px; }
.pt-sm-0 { padding-top: 0; }
.pt-sm-10 { padding-top: 10px; }
.pt-sm-20 { padding-top: 20px; }
.pt-sm-30 { padding-top: 30px; }
.pt-sm-40 { padding-top: 40px; }
.pt-sm-50 { padding-top: 50px; }
.pt-sm-60 { padding-top: 60px; }
.pt-sm-70 { padding-top: 70px; }
.pt-sm-80 { padding-top: 80px; }
.pt-sm-90 { padding-top: 90px; }
.pt-sm-100 { padding-top: 100px; }
.pt-sm-110 { padding-top: 110px; }
.pt-sm-120 { padding-top: 120px; }
.pt-sm-130 { padding-top: 130px; }
.pt-sm-140 { padding-top: 140px; }
.pb-sm-0 { padding-bottom: 0; }
.pb-sm-10 { padding-bottom: 10px; }
.pb-sm-20 { padding-bottom: 20px; }
.pb-sm-30 { padding-bottom: 30px; }
.pb-sm-40 { padding-bottom: 40px; }
.pb-sm-50 { padding-bottom: 50px; }
.pb-sm-60 { padding-bottom: 60px; }
.pb-sm-70 { padding-bottom: 70px; }
.pb-sm-80 { padding-bottom: 80px; }
.pb-sm-90 { padding-bottom: 90px; }
.pb-sm-100 { padding-bottom: 100px; }
.pb-sm-110 { padding-bottom: 110px; }
.pb-sm-120 { padding-bottom: 120px; }
.pb-sm-130 { padding-bottom: 130px; }
.pb-sm-140 { padding-bottom: 140px; }
}

@media only screen and (max-width:480px) {
.mt-xs-0 { margin-top: 0!important; }
.mt-xs-10 { margin-top: 10px; }
.mt-xs-20 { margin-top: 20px; }
.mt-xs-30 { margin-top: 30px; }
.mt-xs-40 { margin-top: 40px; }
.mt-xs-50 { margin-top: 50px; }
.mt-xs-60 { margin-top: 60px; }
.mt-xs-70 { margin-top: 70px; }
.mt-xs-80 { margin-top: 80px; }
.mt-xs-90 { margin-top: 90px; }
.mt-xs-100 { margin-top: 100px; }
.mt-xs-110 { margin-top: 110px; }
.mt-xs-120 { margin-top: 120px; }
.mt-xs-130 { margin-top: 130px; }
.mt-xs-140 { margin-top: 140px; }
.mb-xs-0 { margin-bottom: 0!important; }
.mb-xs-10 { margin-bottom: 10px; }
.mb-xs-20 { margin-bottom: 20px; }
.mb-xs-30 { margin-bottom: 30px; }
.mb-xs-40 { margin-bottom: 40px; }
.mb-xs-50 { margin-bottom: 50px; }
.mb-xs-60 { margin-bottom: 60px; }
.mb-xs-70 { margin-bottom: 70px; }
.mb-xs-80 { margin-bottom: 80px; }
.mb-xs-90 { margin-bottom: 90px; }
.mb-xs-100 { margin-bottom: 100px; }
.mb-xs-110 { margin-bottom: 110px; }
.mb-xs-120 { margin-bottom: 120px; }
.mb-xs-130 { margin-bottom: 130px; }
.mb-xs-140 { margin-bottom: 140px; }
.pt-xs-0 { padding-top: 0; }
.pt-xs-10 { padding-top: 10px; }
.pt-xs-20 { padding-top: 20px; }
.pt-xs-30 { padding-top: 30px; }
.pt-xs-40 { padding-top: 40px; }
.pt-xs-50 { padding-top: 50px; }
.pt-xs-60 { padding-top: 60px; }
.pt-xs-70 { padding-top: 70px; }
.pt-xs-80 { padding-top: 80px; }
.pt-xs-90 { padding-top: 90px; }
.pt-xs-100 { padding-top: 100px; }
.pt-xs-110 { padding-top: 110px; }
.pt-xs-120 { padding-top: 120px; }
.pt-xs-130 { padding-top: 130px; }
.pt-xs-140 { padding-top: 140px; }
.pb-xs-0 { padding-bottom: 0; }
.pb-xs-10 { padding-bottom: 10px; }
.pb-xs-20 { padding-bottom: 20px; }
.pb-xs-30 { padding-bottom: 30px; }
.pb-xs-40 { padding-bottom: 40px; }
.pb-xs-50 { padding-bottom: 50px; }
.pb-xs-60 { padding-bottom: 60px; }
.pb-xs-70 { padding-bottom: 70px; }
.pb-xs-80 { padding-bottom: 80px; }
.pb-xs-90 { padding-bottom: 90px; }
.pb-xs-100 { padding-bottom: 100px; }
.pb-xs-110 { padding-bottom: 110px; }
.pb-xs-120 { padding-bottom: 120px; }
.pb-xs-130 { padding-bottom: 130px; }
.pb-xs-140 { padding-bottom: 140px; }
}